<template>
  <div class="container">
    <div class="title">
      {{ siteUrl }}
    </div>
    <h2>An Internet Website by Jaaromy Zierse</h2>
    <h3>
      In Which Various APIs are Created and Utilized by the Author and Therefore
      Require a Domain of Good Repute from Which to be Referenced
    </h3>
    <h4>Other Internet Websites</h4>
    <div class="link">
      <a
        target="_blank"
        rel="noopener"
        href="https://offcoding.com"
      >A Weblog Maintained by the Whims of the Author</a>
    </div>
    <div class="link">
      <a
        target="_blank"
        rel="noopener"
        href="https://stackoverflow.com/users/205705/jaaromy-zierse"
      >A Profile Page</a>
    </div>
    <div class="link">
      <a
        target="_blank"
        rel="noopener"
        href="https://dealertrack.com"
      >A Fine Company Whereat the Author May be Found</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data: function () {
    return {
      siteUrl: window.location.hostname
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  max-width: 500px;
  margin: auto;
  padding: 20px 10px;
  h1,
  h2,
  h3,
  h4 {
    padding-left: 20px;
    padding-right: 20px;
  }
  h2 {
    margin: 30px auto auto;
    max-width: 300px;
  }
  h3 {
    margin-top: 30px;
  }
  h4 {
    font-size: 1.1em;
    margin-top: 40px;
  }
  .title {
    font-family: "Courier New", Courier, monospace;
    font-size: 1.8em;
    font-weight: 900;
  }
}

.center {
  margin: auto;
}

.link {
  margin-bottom: 15px;
  padding-right: 20px;
  padding-left: 20px;

  a {
    color: #3e6946;
    text-decoration: none;
    font-weight: 600;
  }
  a:hover {
    text-decoration: underline;
  }
}
</style>
