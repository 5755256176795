import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
//import vueHeadful from "vue-headful";

//Vue.config.productionTip = false;
//Vue.component("VueHeadful", vueHeadful);

createApp(App)
  .use(router)
  .mount("#app");
