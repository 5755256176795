import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/Home.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home
  }
];

const router = createRouter({
  history: createWebHistory(),
  base: process.env.BASE_URL,
  routes
});

router.beforeEach(to => {
  window.document.title = window.location.hostname || "(•_•) ( •_•)>⌐■-■ (⌐■_■)";
  return true;
});

export default router;
